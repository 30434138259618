<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What are the safety precautions that go along with using an acid in the lab? Select all that
        apply.
      </p>

      <p v-for="option in options" :key="'pt-1' + option.value" class="mb-3">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="pl-6 mb-n4 mt-0">
          <template #label> {{ option.text }}</template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemOleMiss116PRL2Q2part5',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      items: [
        {text: 'Wear long pants that cover your entire leg', value: 'tackyTrousers'},
        {text: 'Wear splash-proof goggles at all times', value: 'troublesomeTeammates'},
        {
          text: 'Make sure that you are not wearing any dangling jewelry',
          value: 'tangyTrinkets',
        },
        {text: 'Wear shoes that cover your entire foot', value: 'tarnishedToes'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    options() {
      return seededShuffle(this.items, this.seed);
    },
  },
};
</script>
